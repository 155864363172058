import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/imgs/bg_forP.png"});
  background-size: 100% 100%;
  background-repeat: repeat-x;

  @media only screen and (max-width: 767px) {
    background-size: 300% 100%;
  }
`;

export const Content = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;

  .for_content {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;

    > * {
      width: 48%;
    }

    .for_content_img {
      border-radius: 10px;
      img {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }

    .for_content_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 5%;

      label {
        color: #f5674c;
        font-size: 64px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      span {
        color: #f5674c;
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
  }
  .text_orange {
    color: rgb(245, 103, 76);
  }

  @media only screen and (max-width: 991px) {
    .for_content {
      .for_content_text {
        label {
          font-size: 44px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .for_content {
      flex-direction: column;
      > * {
        width: 100%;
      }
    }

    .for_content_img {
      margin-bottom: 25px;
    }
  }
`;
