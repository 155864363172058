import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/imgs/bg_dowload.png"});
  background-size: cover;
  background-repeat: repeat-x;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 50px;

  > *:first-child {
    width: 40%;
    display: flex;
    flex-direction: column;

    > *:first-child {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
    }
  }

  > *:last-child {
    width: 50%;
    display: flex;
    justify-content: center;

    
  }

  .label__top {
    font-weight: 700;
    font-size: 35px;
    color: #f5674c;
  }

  .label__buttom {
    font-weight: 700;
    font-size: 20px;
    color: #f5674c;
  }

  .img__download {
    display: flex;
    width: 330px;
    justify-content: space-between;

    > * {
      width: 45%;
      
      img {
        width: 100%
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .img__download {
      flex-direction: column;
      width: 300px;
      > * {
        margin-bottom : 15px;
      }
    }

    .label__top { 
      font-size: 20px;
    }

    .label__buttom {
      font-size: 14px;
    }

  }
`;
