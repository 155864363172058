import { Content } from "../style/PolicyStyle";
import { useNavigate } from "react-router-dom";
import Navbar from "../componente/Navbar";

const Policy = () => {
  const navigate = useNavigate();
  const onChangeHandle = () => {
    navigate("/");
  };
  window.scrollTo(0, 0);
  return (
    <>
      <Navbar onChangeHandle={onChangeHandle} />
      <div className="container">
        <Content>
          <div className="label__top">
            {/* <img src={process.env.PUBLIC_URL + "/imgs/logo__Klassbits.png"} /> */}
            <label className="lable__heard">Privacy Policy</label>
            <label>
              Privacy Policy You're agreeing to this policy for KlassBits.
            </label>
          </div>
          <div className="label__content">
            <label style={{ marginBottom: 15 }}>
              Last modified: 20th June, 2022
            </label>
            <label style={{ marginBottom: 15 }}>
              At KlassBits, we recognize that privacy and intellectual property
              rights are important to our users. This Privacy Policy applies to
              all products, services and websites offered by 360 Training Center
              Ltd or its affiliated companies, collectively known as KlassBits
              “services”.
            </label>
            <label style={{ marginBottom: 15 }}>
              KlassBits processes personal information only for the purposes
              described in this Privacy Policy including:
            </label>
            <ul>
              <li style={{ marginBottom: 15 }}>
                Providing our services, including the display of customized
                content and advertising, and the recommendation of resources to
                our users;
              </li>
              <li style={{ marginBottom: 15 }}>
                Auditing, research and analysis in order to maintain, protect
                and improve our services;
              </li>
              <li style={{ marginBottom: 15 }}>
                Ensuring the technical functionality of our network;
              </li>
              <li style={{ marginBottom: 15 }}>
                Protecting the rights or property of KlassBits or our users; and
              </li>
              <li style={{ marginBottom: 15 }}>Developing new services.</li>
            </ul>
            <label style={{ marginBottom: 25 }}>
              KlassBits processes personal information on our servers in
              Thailand and in other countries. In some cases, we process
              personal information on a server which may be outside your own
              country. We may process personal information to provide our own
              services. In some cases, we may process personal information on
              behalf of and according to the instructions of a third party, such
              as our advertising partners.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              Option to opt out
            </label>
            <label>
              When you sign up for our service, you will be asked to provide
              personal information. If we use this information in a manner other
              than the purpose for which it was collected, then we will ask for
              your consent prior to such use.
            </label>
            <label style={{ marginBottom: 15 }}>
              If we propose to use personal information for any purposes other
              than those described in this Privacy Policy and/or in the specific
              service privacy notices, we will offer you an effective way to opt
              out of the use of your personal information for those other
              purposes. We will not collect or use sensitive information for
              purposes other than those described in this Privacy Policy, unless
              we have obtained your prior consent.
            </label>
            <label style={{ marginBottom: 15 }}>
              By default, provided information such as the user's name,
              className, school, and country etc might be seen by other
              logged-in users under certain modules during participation of
              certain competitions. The user may choose to opt out by contacting
              us at support@klassbits.com.
            </label>
            <label style={{ marginBottom: 15 }}>
              Most browsers are initially set up to accept cookies, but you can
              reset your browser to refuse all cookies or to indicate when a
              cookie is being sent. However, some KlassBits Product features may
              not function properly if your cookies are disabled.
            </label>
            <label style={{ marginBottom: 25 }}>
              You can decline to submit personal information to any of our
              services, in which case KlassBits may not be able to provide those
              services to you.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              Information sharing
            </label>
            <label style={{ marginBottom: 15 }}>
              Information sharing KlassBits only shares personal information
              with other companies or individuals outside of KlassBits in the
              following limited circumstances:
            </label>
            <ul>
              <li style={{ marginBottom: 15 }}>
                We have your consent. We require opt-in consent for the sharing
                of any sensitive personal information.
              </li>
              <li style={{ marginBottom: 15 }}>
                We provide such information to our subsidiaries, affiliated
                companies or other trusted businesses or persons for the purpose
                of processing personal information on our behalf. We require
                that these parties agree to process such information based on
                our instructions and in compliance with this Privacy Policy and
                any other appropriate confidentiality and security measures.
              </li>
              <li style={{ marginBottom: 15 }}>
                We have a good faith belief that access, use, preservation or
                disclosure of such information is reasonably necessary to (a)
                satisfy any applicable law, regulation, legal process or
                enforceable governmental request, (b) enforce applicable Terms
                of Service, including investigation of potential violations
                thereof, (c) detect, prevent, or otherwise address fraud,
                security or technical issues, or (d) protect against harm to the
                rights, property or safety of KlassBits, its users or the public
                as required or permitted by law.
              </li>
            </ul>
            <label style={{ marginBottom: 25 }}>
              If KlassBits becomes involved in a merger, acquisition, or any
              form of sale of some or all of its assets, we will ensure the
              confidentiality of any personal information involved in such
              transactions and provide notice before personal information is
              transferred and becomes subject to a different privacy policy. We
              may share with third parties certain pieces of aggregated,
              non-personal information, such as the number of users who searched
              for a particular term, for example, or how many users clicked on a
              particular item. Such information does not identify you
              individually.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              Information security
            </label>
            <label>
              We take appropriate security measures to protect against
              unauthorized access to or unauthorized alteration, disclosure or
              destruction of data. These include internal reviews of our data
              collection, storage and processing practices and security
              measures, as well as physical security measures to guard against
              unauthorized access to systems where we store personal data.
            </label>
            <label style={{ marginBottom: 25 }}>
              We restrict access to personal information to KlassBits employees,
              contractors and agents who need to know that information in order
              to operate, develop or improve our services. These individuals are
              bound by confidentiality obligations and may be subject to
              discipline, including termination and criminal prosecution, if
              they fail to meet these obligations.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              Data integrity
            </label>
            <label style={{ marginBottom: 25 }}>
              KlassBits processes personal information only for the purposes for
              which it was collected and in accordance with this Privacy Policy
              or any applicable service-specific privacy notice. We review our
              data collection, storage and processing practices to ensure that
              we only collect, store and process the personal information needed
              to provide or improve our services or as otherwise permitted under
              this criminal prosecution, if they fail to meet these obligations.
            </label>
            <label className="label__heard" style={{ marginBottom: 25 }}>
              Data integrity
            </label>
            <label>
              KlassBits processes personal information only for the purposes for
              which it was collected and in accordance with this Privacy Policy
              or any applicable service-specific privacy notice. We review our
              data collection, storage and processing practices to ensure that
              we only collect, store and process the personal information needed
              to provide or improve our services or as otherwise permitted under
              this Policy. We take reasonable steps to ensure that the personal
              information we process is accurate, complete, and current, but we
              depend on our users to update or correct their personal
              information whenever necessary.
            </label>
            <label style={{ marginBottom: 15 }}>
              Accessing and updating personal information
            </label>
            <label style={{ marginBottom: 25 }}>
              When you use KlassBits services, we make good faith efforts to
              provide you with access to your personal information and either to
              correct this data if it is inaccurate or to delete such data at
              your request if it is not otherwise required to be retained by law
              or for legitimate business purposes. We ask individual users to
              identify themselves and the information requested to be accessed,
              corrected or removed before processing such requests, and we may
              decline to process requests that are unreasonably repetitive or
              systematic, require disproportionate technical effort, jeopardize
              the privacy of others, or would be extremely impractical (for
              instance, requests concerning information residing on backup
              tapes), or for which access is not otherwise required. In any case
              where we provide information access and correction, we perform
              this service free of charge, except if doing so would require a
              disproportionate effort. Some of our services have different
              procedures to access, correct or delete users’ personal
              information. We provide the details for these procedures in the
              specific privacy notices or FAQs for these services.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              Enforcement
            </label>
            <label style={{ marginBottom: 15 }}>
              KlassBits regularly reviews its compliance with this Privacy
              Policy. Please feel free to direct any questions or concerns
              regarding this Privacy Policy or KlassBits’s treatment of personal
              information by contacting us through this web site or by writing
              to us at:
            </label>
            <label>360 Training Center Ltd.</label>
            <label>59/555 Srithamtripidok rd., </label>
            <label>Mung, Phitsanulok </label>
            <label style={{ marginBottom: 15 }}>65000 Thailand</label>
            <label style={{ marginBottom: 25 }}>
              When we receive formal written complaints at this address, it is
              KlassBits’s policy to contact the complaining user regarding his
              or her concerns. We will cooperate with the appropriate regulatory
              authorities, including local data protection authorities, to
              resolve any complaints regarding the transfer of personal data
              that cannot be resolved between KlassBits and an individual.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              Changes to this Privacy Policy
            </label>
            <label>
              Please note that this Privacy Policy may change from time to time.
              We will not reduce your rights under this Privacy Policy without
              your explicit consent, and we expect most such changes will be
              minor. Regardless, we will post any Privacy Policy changes on this
              page and, if the changes are significant, we will provide a more
              prominent notice (including, for certain services, email
              notification of Privacy Policy changes).
            </label>
            <label style={{ marginBottom: 15 }}>
              Each version of this Privacy Policy will be identified at the top
              of the page by its effective date, and we will also keep prior
              versions of this Privacy Policy in an archive for your review.
            </label>
            <label style={{ marginBottom: 15 }}>
              If you have any additional questions or concerns regarding this
              Privacy Policy, please contact us contact us at the following
              address, or by email or phone.
            </label>
            <label>360 Training Center Ltd. </label>
            <label>59/555 Srithamtripidok rd., </label>
            <label>Mung, Phitsanulok </label>
            <label style={{ marginBottom: 15 }}>65000 Thailand </label>
          </div>
        </Content>
      </div>
    </>
  );
}

export default Policy;
