import { Content } from "../style/PolicyStyle";
import { useNavigate } from "react-router-dom";
import Navbar from "../componente/Navbar";

const RefundPolicy = () => {
  const navigate = useNavigate();
  const onChangeHandle = () => {
    navigate("/");
  };
  window.scrollTo(0, 0);
  return (
    <>
      <Navbar onChangeHandle={onChangeHandle} />
      <div className="container">
        <Content>
          <div className="label__top">
            {/* <img src={process.env.PUBLIC_URL + "/imgs/logo__Klassbits.png"} /> */}
            <label className="lable__heard">Refund Policy</label>
            <label>
              Refund Policy By using KlassBits, you agree to the following refund policy terms and conditions.
            </label>
          </div>
          <div className="label__content">
            <label style={{ marginBottom: 15 }}>
              Last modified: 06th June, 2023
            </label>
            <label style={{ marginBottom: 15 }}>
              Thanks for subscribing to our services at KlassBits application
              operated by 360 Training Center Ltd. We offer a full money-back 
              guarantee for all purchases made on our website. If you are not 
              satisfied with the product that you have purchased from us, you can 
              get your money back no questions asked. You are eligible for a full 
              reimbursement within 14 calendar days of your purchase. After the 
              14-day period you will no longer be eligible and won't be able to 
              receive a refund. We encourage our customers to try the or service 
              in the first two weeks after their purchase to ensure it fits your 
              needs. If you have any additional questions or would like to request 
              a refund, feel free to contact us.
            </label>
          </div>
        </Content>
      </div>
    </>
  );
}

export default RefundPolicy;
