import { Content, Background } from "../style/ForParentsStyle";

const ForSchools = () => {
  return (
    <Background>
      <div className="container" st>
        <Content>
          <div className="for_content">
            <div className="for_content_img">
              <img src={process.env.PUBLIC_URL + "/imgs/for_img_3.png"} />
            </div>
            <div className="for_content_text">
              <label>For Parents</label>
              <span>
                การรู้และเข้าใจถึงความสามารถของนักเรียนจะทำให้
                ผู้ปกครองตั้งเป้าหมายให้กับลูกๆได้ถูกทิศทาง
              </span>
              <ul>
                <li>เรียนรู้ได้ตลอดเวลา ข้อมูลไม่สูญหาย</li>
                <li>
                  เรียนรู้ได้ตามลำดับพัฒนาการของตนเอง ผู้ปกครอง
                  ได้มีส่วนช่วยเหลือนักเรียน
                  ได้พัฒนาทั้งความรู้และทักษะตรงให้กับความสามารถชองแต่ละคนได้อย่าง
                  มีประสิทธิภาพ
                </li>
              </ul>
            </div>
          </div>
        </Content>
      </div>
    </Background>
  );
};

export default ForSchools;
