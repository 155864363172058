import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/imgs/bg__TestApp.png"});
  background-size: 100% 100%;
  background-repeat: repeat-x;
`;

export const Content = styled.div`
  padding-top: 10px;
  padding-bottom: 50px;

  .label__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    text-align: center;

    > *:first-child {
      font-weight: 700;
      font-size: 44px;
      color: #f5674c;
      margin-bottom:15px;
    }

    > *:last-child {
      font-weight: 700;
      font-size: 24px;
      color: #f5674c;
    }
  }

  .swiper {
    width: 80%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .swiper-slide {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    > * {
      width: 100%;
    }

    img {
      width: 100px;
      height: 200px;
    }

    &-active {
      img {
        width: 200px;
        height: 350px;
        transition: height 0.5s ease-out;
      }
      height: 350px !important;
    }

    &-next {
      img {
        width: 150px;
        height: 250px;
        transition: height 0.5s ease-out;
      }
      height: 250px !important;
    }

    &-prev {
      img {
        width: 150px;
        height: 250px;
        transition: height 0.5s ease-out;
      }
      height: 250px !important;
    }
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-button-prev {
    color: #f88975;
  }
  .swiper-button-next {
    color: #f88975;
  }

  .swiper-pagination-bullet-active {
    background: #f88975;
  }

  @media only screen and (max-width: 1199px) {
    .swiper {
      width: 100%;
    }
    .label__top {
      > *:first-child {
        font-size: 28px;
      }

      > *:last-child {
        font-size: 16px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .swiper-slide {
      img {
        width: 100px;
        height: 150px;
      }
      &-active {
        img {
          width: 100px;
          height: 250px;
          transition: height 0.5s ease-out;
        }
        height: 250px !important;
      }
      &-next {
        img {
          width: 100px;
          height: 200px;
          transition: height 0.5s ease-out;
        }
        height: 200px !important;
      }

      &-prev {
        img {
          width: 100px;
          height: 200px;
          transition: height 0.5s ease-out;
        }
        height: 200px !important;
      }
    }
  }

  @media only screen and (max-width: 520px) {
    .label__top {
      > *:first-child {
        font-size: 20px;
      }

      > *:last-child {
        font-size: 14px;
      }
    }
  }
`;

export const SwiperPC = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const SwiperMo = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }

  .swiper {
    width: 80%;
  }
`;

export const CardBody = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;

  img {
    object-fit: contain;
    border-radius: 25px;
  }
`;
