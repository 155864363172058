import styled from "styled-components";

export const ButtonBanner = styled.button`
  border: 2px solid #f47a69;
  border-radius: 30px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  background: #f4674c;
  padding: 9px 20px 9px 20px;
  color: #ffffff;
  font-size: 11px;
  margin-right: 20px;
`;
