import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/imgs/bg_forSchool.png"});
  background-size: 100% 100%;
  background-repeat: repeat-x;
`;

export const Content = styled.div`
  padding-top: 150px;
  padding-bottom: 50px;

  .text_title {
    display: flex;
    flex-direction: column;
    width: 48%;

    label {
      color: #f5674c;
      font-weight: 700;
      font-size: 44px;
    }

    span {
      color: #f5674c;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }

  .for_content {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;

    > * {
      width: 48%;
    }

    .for_content_img {
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }

    .for_content_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 25px;
      padding-right: 5%;

      label {
        color: #f5674c;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .text_title {
      width: 100%;
    }

    .for_content {
      flex-direction: column;
      > * {
        width: 100%;
      }

      .for_content_text {
        margin-left: 0px;
        padding-right: 0px;
      }

      .for_content_img {
        margin-bottom : 25px;
      }


    }

    .for_content_mobile  {
      flex-direction: column-reverse ;
    }

  }
`;
