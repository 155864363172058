import { Content, Background } from "../style/ForSchoolsStyle";

const ForSchools = () => {
  return (
    <Background>
      <div className="container">
        <Content>
          <div className="text_title">
            <label>For Schools</label>
            <span>การวิเคราะห์และประเมินผลของ KlassBits</span>
            <div>
              สามารถประเมินศักยภาพตามสมรรถนะ ความสามารถได้ตรงตามหลักสูตรฐาน
              สมรรถนะซึ่งเป็นการประเมินที่แม่นยำและทำให้เสริมจุดเด่นและพัฒนาศักยภาพ
              ได้ตรงตามความต้องการ อย่างอัตโนมัติและต่อเนื่อง
            </div>
          </div>

          <div className="for_content">
            <div className="for_content_img">
              <img src={process.env.PUBLIC_URL + "/imgs/for_img_1.png"} />
            </div>
            <div className="for_content_text">
              <label>
                เครื่องมือพัฒนาศักยภาพและรวบรวมข้อมูล
                ผลการศึกษาของนักเรียนรายบุคคล
              </label>
              <div>
                ที่สามารถเชื่อมโยงข้อมูลระหว่าง นักเรียน ผู้ปกครอง ครู
                และผู้บริหาร โรงเรียนแบบ real time
                โดยมีระบบประเมินผลการเรียนตามเกณฑ์ มาตรฐานกระทรวงศึกษาธิการ
                และโปรแกรมประเมินสมรรถนะนักเรียน มาตรฐานสากล (PISA)
                ที่สามารถวิเคราะห์ และรายงานผลตามการเรียนรู้
                ทักษะทางวิชาการและความสามารถ เฉพาะด้านลดความเครียดและความ
                คาดหวังซึ่งทำให้เกิดปัญหาจิตวิทยาในวัยเรียนและลดเวลาเรียนเนื่องจากทุกคนมีเป้าหมายที่ชัดเจนร่วมกัน
              </div>
            </div>
          </div>

          <div className="for_content for_content_mobile">
            <div className="for_content_text">
              <label>
                KlassBits ช่วยให้เด็กๆตามหาฝันได้อย่างเป็นตัวเอง
                ส่งเสริมพ่อแม่เข้าใจลูก
              </label>
              <ul>
                <li>
                  เป็นการใช้เทคโนโลยีการประมวลผลโดย สมาการทางคณิตศาสตร์
                  Computerized Adaptive Testing (CAT) ที่สามารถเข้าถึง
                  ระดับความรู้ของนักเรียนในแต่ละทักษะรายวิชา
                </li>
                <li>
                  พัฒนาเฉพาะบุคคล และยังได้ใช้การวิเคราะห์อัจฉริยภาพทั้ง 8 ด้าน
                  Multiple Intelligent มาวิเคราะห์ความถนัด ของนักเรียน ทำให้รู้
                  ว่านักเรียนแต่ละคนต้องฝึกฝนอะไร
                </li>
                <li>
                  สามารถพัฒนาการเรียนรู้ของนักเรียนเพิ่มขึ้นถึง 21%
                  และยังสามารถ ลดเวลาการเรียนรู้ได้ถึง
                  50%และระบบยังช่วยลดเวลาในการวิเคราะห์
                  ข้อมูลเพื่อประเมินผลสำหรับคุณครูมากกว่าร้อยละ 80
                </li>
              </ul>
            </div>
            <div className="for_content_img">
              <img src={process.env.PUBLIC_URL + "/imgs/for_img_2.png"} />
            </div>
          </div>
        </Content>
      </div>
    </Background>
  );
};

export default ForSchools;
