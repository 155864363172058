import { Background, Contents, AboutContents } from "../style/BannerStyle";
import { ButtonBanner } from "../style/ButtonBanner";
import { ButtonGet } from "../style/ButtonGet";
import { MdOutlineArrowRight } from "react-icons/md";

const Banner = () => {
  return (
    <Background>
      <div className="container">
        <Contents>
          <div>
            <div className="logo__name">
              <img src={process.env.PUBLIC_URL + "/imgs/banner_name.png"} />
            </div>
            <label className="label__center">
              ระบบประเมินและเสริมสร้างศักยภาพ
            </label>
            <label className="label__center">
              เรียนรู้รายบุคคลสำหรับนักเรียน
            </label>
          </div>

          <div>
            <img
              src={process.env.PUBLIC_URL + "/imgs/banner_img_top.png"}
              className="img__bg__top"
            />
            <img
              src={process.env.PUBLIC_URL + "/imgs/banner_img.gif"}
              className="img__gif"
            />
            <img
              src={process.env.PUBLIC_URL + "/imgs/banner_img_bottom.png"}
              className="img__bg__buttom"
            />
          </div>
        </Contents>

        <AboutContents>
          <div className="abous__content__top">
            <div className="img__about">
              <img src={process.env.PUBLIC_URL + "/imgs/about_img.png"} />
            </div>
            <div>
              <label className="label__au__top">Klass Bits</label>
              <label className="label__au_center">
                ระบบประเมินและเสริมสร้างศักยภาพ
              </label>
              <label className="label__au_center">
                เรียนรู้รายบุคคลสำหรับนักเรียน
              </label>

              <ul className="span_au">
                <li>
                  แนะนำแนวทางพัฒนาแต่ละทักษะทางวิชาการและความสามารถ
                  เฉพาะด้านที่เหมาะกับระดับศักยภาพของแต่ละบุคคล
                </li>
                <li>
                  สร้างความยืดหยุ่นในการเรียนการสอน และเชื่อมโยงข้อมูลผลการศึกษา
                </li>
                <li>
                  วิเคราะห์ผลการเรียน ประสิทธิภาพการสอน ให้กับผู้ปกครอง ครู
                  และโรงเรียน ทราบข้อมูลได้อย่างทันท่วงที
                </li>
              </ul>
            </div>
          </div>

          <div className="abous__content__bottom">
            <div>
              <label>เรียนรู้ในรูปแบบเฉพาะตน</label>
              <span>
                พัฒนาการเรียนรู้ตามระดับความสามารถของตนเอง และสนุกกับการเรียนรู้
              </span>
              <img src={process.env.PUBLIC_URL + "/imgs/about_img_1.png"} />
            </div>
            <div>
              <label>ส่งเสริมศักยภาพตรงจุด</label>
              <span>
                แนะนำศักยภาพนักเรียนรายบุคคลจาก การวิเคราะห์ ผลอัจฉริยภาพทั้ง 8
                ด้าน
              </span>
              <img src={process.env.PUBLIC_URL + "/imgs/about_img_2.png"} />
            </div>
          </div>

          <div className="abous__content__bottom">
            <div>
              <label>ผลการวิเคราะห์แม่นยำ</label>
              <span>
                การวัดผลตามทักษะรายวิชา อย่างแม่นยำ เพื่อการพัฒนาที่ ตรงจุด
                ไม่เสียเวลา
              </span>
              <img src={process.env.PUBLIC_URL + "/imgs/about_img_3.png"} />
            </div>
            <div>
              <label>เชื่อมโยงข้อมูลทุกฝ่าย</label>
              <span>
                ทั้งนักเรียน ครู โรงเรียน และผู้ปกครอง เข้าถึงข้อมูล ได้อย่าง
                real time
              </span>
              <img src={process.env.PUBLIC_URL + "/imgs/about_img_4.png"} />
            </div>
          </div>
        </AboutContents>
      </div>
    </Background>
  );
};

export default Banner;
