import { Content } from "../style/PolicyStyle";
import { useNavigate } from "react-router-dom";
import Navbar from "../componente/Navbar";

const TermsConditions = () => {
  const navigate = useNavigate();
  const onChangeHandle = () => {
    navigate("/");
  };
  window.scrollTo(0, 0);
  return (
    <>
      <Navbar onChangeHandle={onChangeHandle} />
      <div className="container">
        <Content>
          <div className="label__top">
            {/* <img src={process.env.PUBLIC_URL + "/imgs/logo__Klassbits.png"} /> */}
            <label className="lable__heard">Terms & Conditions</label>
          </div>
          <div className="label__content">
            <label style={{ marginBottom: 15 }}>
              Last modified: 06th June, 2023
            </label>
            <label style={{ marginBottom: 25 }}>
              By subscribing to our services at KlassBits application as a user (“You”), 
              You agree to the following Terms and Conditions and Privacy Policy. 
              Please read them carefully before using this KlassBits application.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              GENERAL PROVISIONS
            </label>
            <label style={{ marginBottom: 15 }}>
              This KlassBits application is owned and operated by 360 Training 
              Center Ltd (hereafter “Our”, “We” or “Company”). Our principal 
              place of business is located at 360 Training Center Ltd. 59/555 
              Srithamtripidok Rd., Nei Mung, Phitsanulok 65000, Thailand. 

              Use of this KlassBits application is at Your own risk. We host 
              this site on a reputable platform and take reasonable efforts to 
              maintain and host the site. However, We make no explicit 
              representations or warranties as to the safety or Your individual 
              use of the application. The terms and conditions contained on 
              this page is subject to change at any time.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              AGE REQUIREMENTS
            </label>
            <label style={{ marginBottom: 15 }}>
              You must be at least 5 to use Our application, courses, and products.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              INTELLECTUAL PROPERTY NOTICE
            </label>
            <label style={{ marginBottom: 15 }}>
              All text, photographs, graphics and other materials on this 
              site are subject to the copyrights and other intellectual 
              property rights of 360 Training Center Ltd and are protected by 
              Thai Law.  Website materials may not be copied for any reason, 
              including your personal use, commercial use, or distribution, 
              nor may these materials be modified or reposted to other sites, 
              without the prior express written permission of Company. We may 
              prosecuted You to the fullest extent permissible should We choose 
              to do so, including asking for financial penalties (damages) 
              and/or an injunction forcing You to stop using Our intellectual 
              property immediately.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              DIGITAL PRODUCTS
            </label>
            <label style={{ marginBottom: 15 }}>
              By purchasing any product from 360 Training Center Ltd on this 
              application and website, you are granted one revocable, worldwide, 
              non-exclusive license to the product(s) you have purchased. If 
              you violate this license by giving or selling a copy of our 
              product(s) to anyone, We reserve the right to invoice you for the 
              licenses you have gifted to others and revoke your access to our 
              products permanently.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              DISCLAIMERS
            </label>
            <label style={{ marginBottom: 15 }}>
            Our website and related materials are provided for educational and 
            informational use only. You agree to indemnify and hold harmless 
            Our website and company for any direct or indirect loss or conduct 
            incurred as a result of Your use of Our website and any related 
            communications, including as a result of any consequences incurred 
            from technological failures such as a payment processor error(s) or 
            system failure(s).

            While We may reference certain results, outcomes or situations on 
            this website, You understand and acknowledge that We make no guarantee 
            as to the accuracy of third-party statements contained herein or the 
            likelihood of success for You as a result of these statements or any 
            other statements anywhere on this website. If You have medical, legal 
            or financial questions, You should consult a medical professional, 
            lawyer or CPA and/or CFP respectively. We expressly disclaim any and 
            all responsibility for any actions or omissions You choose to make as
            a result of using this website, related materials, products, courses 
            or the materials contained herein.

            While Company may offer discounts or offers at various times, these 
            discounts or offers may be terminated or amended at any time without 
            explanation or warning. Sales, discounts, and offers will not be 
            retroactively applied to past purchases.

            This website is updated on a regular basis and while We try to make 
            accurate statements in a timely and effective manner, We cannot 
            guarantee that all materials and related media contained herein are 
            entirely accurate, complete or up to date. You expressly acknowledge 
            and understand that any information or knowledge You gain as a result 
            of using this website is used at Your own risk. If You should see any 
            errors or omissions and would like to let us know, please email us at 
            hello@klassbits.com.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              ADVERTISEMENTS
            </label>
            <label style={{ marginBottom: 15 }}>
              We do not necessarily endorse or recommend any of the goods or 
              services advertised on or through our websites. We do not necessarily 
              endorse or recommend any affiliates using our services.
            </label>
            <label className="label__heard" style={{ marginBottom: 15 }}>
              CONSENT
            </label>
            <label style={{ marginBottom: 15 }}>
              By using this application and website, You hereby consent to these 
              Terms and Conditions of Use.
            </label>
            <label style={{ marginBottom: 15 }}>
              Contact us info
            </label>
            <label style={{ marginBottom: 15 }}>360 Training Center Ltd. </label>
            <label style={{ marginBottom: 15 }}>59/555 Srithamtripidok rd., </label>
            <label style={{ marginBottom: 15 }}>Mung, Phitsanulok </label>
            <label style={{ marginBottom: 15 }}>65000 Thailand </label>
            <label style={{ marginBottom: 15 }}>Tel 088 293 0956 </label>
            <label style={{ marginBottom: 15 }}>Email: hello@klassbits.com </label>
          </div>
        </Content>
      </div>
    </>
  );
}

export default TermsConditions;
