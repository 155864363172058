import { Background, FooterBottom, Content } from "../style/FooterStly";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsFillTelephoneFill, BsBuildings } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

const Footer = ({ onChangeHandle }) => {
  return (
    <>
      <Background>
        <div className="container">
          <Content>
            <div className="img_logo">
              <img src={process.env.PUBLIC_URL + "/imgs/logo__Klassbits.png"} />
              <label>โครงการนี้ได้รับการสนับสนุนจาก</label>
              <div className="img_logo_blog">
                <img src={process.env.PUBLIC_URL + "/imgs/logo_NIA.png"} />
              </div>
              <label>พันธมิตรกับ </label>
              <div className="img_logo_blog">
                <img src={process.env.PUBLIC_URL + "/imgs/logo_KMITL.png"} />
              </div>
            </div>

            <div className="menu_tab">
              <label>INFROMATION</label>
              <span className="link_text" onClick={() => onChangeHandle("About")} > Home</span>
              <span className="link_text" onClick={() => onChangeHandle("About")} >About Us</span>
              <span className="link_text" onClick={() => onChangeHandle("Schools")} >For Schools</span>
              <span className="link_text" onClick={() => onChangeHandle("Parents")} >For Parents</span>
              <span className="link_text" onClick={() => onChangeHandle("Courses")} >Courses</span>
              <span className="link_text" onClick={() => onChangeHandle("Contact")} >Contact Us</span>
              <Link to="/policy" className="link_text">
                Privacy Policy
              </Link>
              <Link to="/terms-conditions" className="link_text">
                Terms & Conditions
              </Link>
              <Link to="/refund-policy" className="link_text">
                Refund Policy
              </Link>
            </div>

            <div className="address_tab">
              <label>CONTACT</label>
              <div>
                <BsBuildings size={24} />
                <span>360 Training Center Ltd.</span>
              </div>

              <div>
                <BsFillTelephoneFill size={24} />
                <div>
                  <div>Company Registration:</div>
                  <div>0655558000621</div>
                </div>
              </div>

              <div>
                <FaMapMarkerAlt size={34} />
                <span>
                  59/555 ถนนศรีธรรมไตรปิฎก ต.ในเมือง อ.เมือง จ.พิษณุโลก 65000
                </span>
              </div>

              <div>
                <BsFillTelephoneFill size={24} />
                <span>088 293 0956</span>
              </div>

              <div>
                <GrMail size={24} />
                <span>hello@klassbits.com</span>
              </div>
            </div>

            <div className="social_tab">
              <label>SOCIAL</label>
              <div>
                <div>
                  <img src={process.env.PUBLIC_URL + "/imgs/icon_line.png"} />
                </div>
                <div><img src={process.env.PUBLIC_URL + "/imgs/icon_fb.png"} /></div>
                <div><img src={process.env.PUBLIC_URL + "/imgs/icon_ig.png"} /></div>
                <div><img src={process.env.PUBLIC_URL + "/imgs/icon_titok.png"} /></div>
              </div>
            </div>
          </Content>
        </div>
      </Background>
      <FooterBottom>
        <div className="container">
          <label className="label_text">
            Copyright © 2022 Klass Bits All rights reserved.
          </label>
        </div>
      </FooterBottom>
    </>
  );
};

export default Footer;
