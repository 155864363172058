import KlassBitsPage from "./webPage/KlassBitsPage";
import Policy from "./webPage/Policy"
import RefundPolicy from "./webPage/RefundPolicy"
import TermsConditions from "./webPage/TermsConditions"

import { BrowserRouter, Routes, Route } from "react-router-dom";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<KlassBitsPage />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
