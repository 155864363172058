import { Content, ContentMobile, MenuMobile } from "../style/NavbarStyle";
import { ButtonGet } from "../style/ButtonGet";
import { MdOutlineArrowRight } from "react-icons/md";
import { TbMenu2 } from "react-icons/tb";
import { BsArrowRight } from "react-icons/bs";

import React, { useState } from "react";

const Navbar = ({ onChangeHandle }) => {
  const [open, setOpen] = useState("70px");
  const [body, setBody] = useState("none");

  const clickHamburger = () => {
    setOpen("0px");
    setBody("100%");
  };

  const clickClose = () => {
    setOpen("70px");
    setBody("0%");
  };

  return (
    <>
      <Content>
        <div className="container">
          <div className="nav__boby">
            <div className="nav__logo" onClick={() => onChangeHandle("About")}>
              <img src={process.env.PUBLIC_URL + "/imgs/logo__Klassbits.png"} />
            </div>
            <div className="nav__menu">
              <div onClick={() => onChangeHandle("About")}>About Us</div>
              <div onClick={() => onChangeHandle("Schools")}>For Schools</div>
              <div onClick={() => onChangeHandle("Parents")}>For Parents</div>
              <div onClick={() => onChangeHandle("Courses")}>Courses</div>
              <div onClick={() => onChangeHandle("Contact")}>Contact Us</div>
            </div>
            <a href="https://admin.klassbits.com/Login" target="_blank">
              <ButtonGet>
                LOGIN
                <MdOutlineArrowRight size={38} />
              </ButtonGet>
            </a>
          </div>
        </div>
      </Content>
      <ContentMobile style={{ height: `${open}` }}>
        <div className="container">
          <div className="img_logo">
            <img
              src={process.env.PUBLIC_URL + "/imgs/banner_name.png"}
              onClick={() => onChangeHandle("About")}
            />
            <TbMenu2 size={30} onClick={() => clickHamburger()} />
          </div>
        </div>
      </ContentMobile>
      <MenuMobile style={{ width: `${body}` }}>
        <div className="container">
          <div className="logo_top">
            <img
              src={process.env.PUBLIC_URL + "/imgs/banner_name.png"}
              onClick={() => onChangeHandle("About")}
            />
            <BsArrowRight size={30} onClick={() => clickClose()} />
          </div>
          <div className="text_menu">
            <div
              onClick={() => {
                onChangeHandle("About");
                clickClose();
              }}
            >
              About Us
            </div>
            <div
              onClick={() => {
                onChangeHandle("Schools");
                clickClose();
              }}
            >
              For Schools
            </div>
            <div
              onClick={() => {
                onChangeHandle("Parents");
                clickClose();
              }}
            >
              For Parents
            </div>
            <div
              onClick={() => {
                onChangeHandle("Courses");
                clickClose();
              }}
            >
              Courses
            </div>
            <div
              onClick={() => {
                onChangeHandle("Contact");
                clickClose();
              }}
            >
              Contact Us
            </div>
          </div>

          <div className="bt-login">
            <a href="https://admin.klassbits.com/Login" target="_blank">
              <ButtonGet>
                LOGIN
                <MdOutlineArrowRight size={38} />
              </ButtonGet>
            </a>
          </div>
        </div>
      </MenuMobile>
    </>
  );
};

export default Navbar;
