import { Content, ContentLink, Background } from "../style/ContactStyle";
import { ButtonGet } from "../style/ButtonGet";
import { MdOutlineArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: '1px solid #f4674c',
    color: '#f4674c',
    fontSize: '24px',
    padding: '10%',
  },
  overlay: {
    zIndex: '10',
    backgroundColor: 'none'
  },
};

const Contact = () => {
  const [email, setEmail] = useState("");
  const [openModal, setOpenMpdal] = useState(false);
  const [message , setmessage] = useState("");

  const sendEmail = async () => {

    if (email !== "") {
      try {
        await axios.post(
          `https://api-klassbits.klassbits.com/api/v1/supports`,
          { detail: `${email}` }
        );
        setmessage("ส่งอีเมลเรียบร้อยแล้ว");
      } catch (err) {
        setmessage("!ERROR การส่งข้อความล้มเหลว");
        console.log(err);
      }
    } else {
      setmessage("กรุณากรอกอีเมล");
    }
    Open();
  };

  const Open = () => {
    setOpenMpdal(true);
  };


  const closeModal = () => {
    setOpenMpdal(false);
  };

  return (
    <Background>
      <div className="container">
        <Content>
          <div className="bt__klass">
            <img src={process.env.PUBLIC_URL + "/imgs/logo__Klassbits.png"} />
          </div>
          <div className="input__grop">
            <label>Contact us</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your E-mail / Tel."/>
          </div>
          <div >
            <ButtonGet onClick={sendEmail} className="bt_mobile">
              SEND
              <MdOutlineArrowRight size={38} />
            </ButtonGet>
          </div>
          <Modal
            isOpen={openModal}
            onRequestClose={closeModal}
            style={customStyles}
            ariaHideApp={false}
          >
            <div>{message}</div>
          </Modal>
        </Content>
      </div>
    </Background>
  );
};

export default Contact;
