import styled from "styled-components";

export const Background = styled.div`
  background-color: #f4674c;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.25);
`;

export const Content = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  > * {
    width: 250px;
  }

  .img_logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      color: #ffffff;
      font-size: 14px;
    }

    > * {
      margin-bottom: 20px;
    }

    img {
      width: 180px;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));
    }

    .img_logo_blog {
      background-color: white;
      border-radius: 10px;
      width: 180px;
      padding: 10px;

      img {
        width: 100%;
      }
    }
  }

  .menu_tab {
    display: flex;
    flex-direction: column;
    padding-left: 4%;
    margin-top:30px;

    label {
      margin-bottom: 15px;
      font-weight: 500;
      color: white;
      font-size: 18px;
    }

    .link_text {
      color: white;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 300;
      cursor: pointer;
      text-decoration: unset;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .address_tab {
    display: flex;
    flex-direction: column;
    margin-top:30px;

    label {
      margin-bottom: 15px;
      font-weight: 500;
      color: white;
      font-size: 18px;
    }

    > * {
      display: flex;
      justify-content: space-between;
      margin-bottom:25px;

      > *:first-child {
        width: 8%;
        color: white;
      }
      
      > *:last-child {
        font-size: 14px;
        color: white;
        width: 88%;
      }
    }
  }

  .social_tab {
    display: flex;
    flex-direction: column;
    margin-top:30px;

    label {
      margin-bottom: 15px;
      font-weight: 500;
      color: white;
      font-size: 18px;
    }

    > * {
      display flex;
      justify-content: space-between;

      > * {
        background-color: white;
        border-radius: 50%;
        width: 45px;
        height: 45px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

`;

export const FooterBottom = styled.div`
  background-color: #c3351a;
  .label_text {
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;
