import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/imgs/bg__courses.png"});
  background-size: 100% 100%;
  background-repeat: repeat-x;

  @media only screen and (max-width: 767px) {
    background-size: 300% 100%;
  }

`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 150px;
  padding-bottom: 250px;
  
  > *:first-child {
    width: 48%;
    display: flex;
    flex-direction: column;
  }

  > *:last-child {
    width: 48%;
    display: flex;
    flex-direction: column;
    margin-left: 50xp;
  }

  .label__top {
    color: #f5674c;
    font-weight: 700;
    font-size: 64px;
  }

  .label__bottom {
    color: #f5674c;
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 10px;
  }

  .img_bottom {
    width: 250px;
  }

  .span__text {
    font-weight: 400;
    font-size: 14px;
  }

  .crad__content {
    display: flex;
    flex-direction: column;
    width: 100%;

    > *:last-child {
      margin-top: 50px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }

  .crad__body {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 24px 60px -34px #f5674c;
    border-radius: 10px;
    background: #ffffff;
    width: 300px;
    height: 157px;
    position: relative;
    padding: 20px 0px 0px 15px;

    label {
      font-weight: 700;
      font-size: 16px;
      color: #f34c2c;
      margin-bottom: 20px;
    }

    span {
      font-weight: 600;
      font-size: 22px;
      margin-top: 20px;
    }

    img {
      width: 155px;
      height: 120px;
      position: absolute;
      top: -40px;
      right: 0px;
    }
  }

  .card_download {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 20px;
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    z-index: 1;
    margin-top: 40px;
    max-width: 400px;

    .price {
      font-weight: 800;
      font-size: 36px;
      margin-bottom: 20px;

      span {
        font-weight: 600;
        font-size: 18px;
        color: #212529;
        margin-right: 10px;
        overflow: hidden;
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        line-height: 18px;

        :before {
          width: 100%;
          height: 1.5px;
          background: rgb(255, 0, 0);
          position: absolute;
          left: 0px;
          top: 6px;
          content: "";
          transform: rotate(352deg);
        }
      }
    }
    .img__download {
      display: flex;
      gap: 16px;
      margin-top:10px;

      img {
        max-width: 100%;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    > *:first-child {
      width: 50%;
    }

    > *:last-child {
      width: 50%;
    }

    .crad__content {
      margin-top: 30px;
      flex-direction: column;
    }

    .crad__body {
      margin-top: 30px;
    }

    .span__text {
      margin-right: 0px;
    }
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    > *:first-child {
      width: 100%;
    }
    > *:last-child {
      width: 100%;
    }

    .label__bottom {
      font-size: 20px;
    }

    .crad__content {
      align-items: center;
      > *:first-child {
        margin-right: 0px;
      }
      > *:last-child {
        justify-content: center;
      }
    }
  }
`;
