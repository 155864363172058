import styled from "styled-components";

export const Background = styled.div`
  background-image: url(${process.env.PUBLIC_URL + "/imgs/bg__banner.png"});
  background-size: 100% 60%;
  background-repeat: repeat-x;

  @media only screen and (max-width: 991px) {
    background-size: 100% 40%;
  }

  @media only screen and (max-width: 768px) {
    background-size: cover;
  }
`;

export const Contents = styled.div`
  display: flex;
  padding-top: 150px;

  > *:first-child {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-right: 19px;
  }
  > *:last-child {
    width: 60%;
    display: flex;
    align-items: flex-end;
    margin-left: 19px;
    position: relative;

    .img__bg__buttom {
      width: 100%;
      z-index: 2;
    }

    .img__bg__top {
      position: absolute;
      width: 250px;
      top: 0;
      right: 30%;
    }

    .img__gif {
      width: 470px;
      position: absolute;
      bottom: -50px;
      left: 60px;
    }
  }

  .label__center {
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
  }

  .logo__name {
    margin-bottom: 25px;
    img {
      width: 120%;
    }
  }

  .label__bottom {
    font-weight: 400;
    font-size: 14px;
    color: #ffebbd;
    margin-top: 10px;
    margin-bottom: 26px;
  }

  .grop__button {
    display: flex;
    margin: 10px 0px 10px 0px;
    margin-bottom: 63px;
  }

  @media only screen and (max-width: 1199px) {
    > *:last-child {
      .img__gif {
        width: 420px;
        left: 33px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    > *:first-child {
      width: 100%;
      margin-bottom: 35px;
      align-items: center;
    }
    > *:last-child {
      width: 100%;
      .img__gif {
        left: 103px;
      }
    }
    .logo__name {
      margin-bottom: 25px;
      img {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    > *:first-child {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 40px;
    }

    > *:last-child {
      width: 100%;
      margin-left: 0px;
      .img__gif {
        left: 35px;
      }
    }
  }

  @media only screen and (max-width: 520px) {
    > *:last-child {
      .img__bg__top {
        width: 200px;
      }
      .img__gif {
        width: 320px;
        bottom: -35px;
        left: 40px;
      }
    }
  }

  @media only screen and (max-width: 420px) {
    .label__center {
      font-size: 18px;
    }

    > *:last-child {
      .img__bg__top {
        width: 150px;
      }
      .img__gif {
        width: 300px;
        bottom: -35px;
        left: 20px;
      }
    }
  }
`;

export const AboutContents = styled.div`
  background-color: hsl(0, 0%, 100%);
  margin-top: 100px;
  box-shadow: 0px 50px 40px -20px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 35px;

  .abous__content__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;

    > *:first-child {
      width: 48%;
    }

    > *:last-child {
      width: 48%;
      display: flex;
      flex-direction: column;
    }

    .img__about {
      padding: 20px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }

    .label__au__top {
      font-weight: 700;
      font-size: 70px;
      color: #f5674c;
    }

    .label__au_center {
      font-weight: 700;
      font-size: 24px;
      color: #f5674c;
    }

    .span_au {
      margin-top: 20px;
      > * {
        font-weight: 400;
        font-size: 14px;
        padding-right: 50px;
        margin-bottom: 20px;
      }
    }

    @media only screen and (max-width: 991px) {
      .label__au__top {
        font-size: 40px;
      }
      .label__au_center {
        font-size: 18px;
      }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
      > *:first-child {
        width: 70%;
      }
      > *:last-child {
        width: 100%;
      }
      .span_au {
        > * {
          padding-right: 0px;
        }
      }
      .group_bt_au {
        padding-right: 0px;
        grid-template-columns: auto auto;
      }
    }

    @media only screen and (max-width: 420px) {
      > *:first-child {
        width: 100%;
      }
    }
    
  }

  .abous__content__bottom {
    display: flex;
    justify-content: space-around;
    margin-bottom: 45px;
    > * {
      width: 35%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      label {
        color: #f5674c;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      span {
        margin-bottom: 25px;
      }
    }

    @media only screen and (max-width: 991px) {
      > * {
        label {
          font-size: 20px;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;

      > *:first-child {
        width: 70%;
        margin-bottom: 25px;
      }
      > *:last-child {
        width: 70%;
        margin-bottom: 25px;
      }
    }

    @media only screen and (max-width: 420px) {
      > *:first-child {
        width: 100%;
      }
      > *:last-child {
        width: 100%;
      }
    }
  }
`;
