import { Background, Content } from "../style/CoursesStyle";
import { useState, useEffect } from "react";

const Courses = () => {
  const [price, setPrice] = useState(2500);
  const [total, setTotal] = useState(2500);
  const [promotionRate, setPromotionRate] = useState(0);

  useEffect(() => {
    fetch("https://api-klassbits.klassbits.com/api/v1/prices/get-active-price")
      .then((response) => response.json())
      .then((data) => {
        setPrice(2500);
        setTotal(1750);
        setPromotionRate(30);
        if(data.price){
          setPrice(data.price);
          setTotal(data.total);
          setPromotionRate(data.promotionRate);
        }
      })
      .catch((error) => {
        console.error("Error fetching prices from API:", error);
      });
  }, []);
  return (
    <Background>
      <div className="container">
        <Content>
          <div>
            <label className="label__top">Courses</label>
            <label className="label__bottom">ชุดคำถามและแบบฝึกหัด</label>
            <ul>
              <li>
                คณิตศาสตร์ และ วิทยาศาสตร์
                ชุดแบบฝึกหัดวิชาคณิตศาสตร์และวิทยาศาสตร์ พัฒนาโดยคณะจารย์จาก
                สถาบันเทคโนโลยีพระจอมเกล้าเจ้าคุณทหารลาดกระบัง (สจล.)
              </li>
            </ul>
            <label className="label__bottom">
              โดยหลักสูตรสอดคล้องกับ สถาบันส่งเสริมการสอน วิทยาศาสตร์และเทคโนโลยี
              (สสวท.)
            </label>
            <div >
              <img className="img_bottom" src={process.env.PUBLIC_URL + "/imgs/logo_KMITL.png"} />
            </div>
          </div>

          <div>
            <div className="crad__content">
              <div className="crad__body">
                <label>Mathematics</label>
                <span>วิชาคณิตศาสตร์</span>
                <img src={process.env.PUBLIC_URL + "/imgs/crad__img7.png"} />
              </div>

              <div>
                <div className="crad__body">
                  <label>Science</label>
                  <span>วิชาวิทยาศาสตร์</span>
                  <img src={process.env.PUBLIC_URL + "/imgs/crad__img8.png"} />
                </div>
              </div>
            </div>
            
            <div className="card_download">
              <p>Download Klassbits app and get <strong>30-days free trial</strong> with limited-time special price
              {promotionRate > 0 && (
                <>,{" "} {promotionRate}% OFF!</>
              )}</p>
              <div className="price">
                {promotionRate > 0 && (
                  <><span>{price} baht</span></>
                )}
                {total} baht
              </div>
              <div className="text_orange">Download Now</div>
              <div className="img__download">
                <a href="https://play.google.com/store/apps/details?id=com.klassbits.klassbits" target="_blank">
                  <img
                    src={process.env.PUBLIC_URL + "/imgs/android__download.png"}
                  />
                </a>
                <a href="https://apps.apple.com/sa/app/klassbits/id1630706840?uo=2" target="_blank">
                  <img src={process.env.PUBLIC_URL + "/imgs/download__ios.png"} />
                </a>
              </div>
            </div>
          </div>
        </Content>
      </div>
    </Background>
  );
};

export default Courses;
