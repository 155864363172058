import styled from "styled-components";

export const Background = styled.div`
  background-color: #f4674c;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.25);
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 80px;
  padding-top: 80px;
  align-items: center;

  .bt__klass {
    width: 180px;
    height: 60px;
    img {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));
    }
  }

  .input__grop {
    background-color: #c3351a;
    display: flex;
    align-items: center;
    height: 60px;

    padding: 13px 10px 13px 39px;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50px;

    label {
      color: #ffffff;
      font-weight: 600;
      font-size: 28px;
      margin-right: 30px;
    }

    input {
      background: #ffffff;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
      border-radius: 33.5px;
      width: 450px;
      height: 40px;
      border: 0px;
      padding-left: 20px;
    }
  }

  .Overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 10;
    margin: 20%;
  }

  @media only screen and (max-width: 1199px) {

    .input__grop {
      label {
        font-size: 20px;
        margin-right: 20px;
      }
      input {
        width: 350px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .bt__klass {
      width: 150px;
      height: 60px;
    }

    .input__grop {
      label {
        font-size: 16px;
        margin-right: 15px;
      }
      input {
        width: 250px;
        height: 40px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;

    > * {
      margin-bottom: 20px;
    }

    .bt__klass {
      width: 180px;
      height: 60px;
    }

    .input__grop {
      padding: 13px 10px 13px 13px;
      flex-direction: column;
      height: 100px;
      width: 80%;
      border-radius: 25px;
      label {
       margin-bottom: 10px;
      }
      input {
        width: 100%;
        height: 40px;
      }
    }

    .bt_mobile {
      width: 160px;
    }
  }
`;

export const ContentLink = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 19px;

  .link__text {
    color: #ffffff;
    font-size: 24px;
  }
`;
