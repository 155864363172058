import { Content, Background } from "../style/DownloadStyle";
import { Link } from "react-router-dom";

const DownloadPage = () => {
  return (
    <Background>
      <div className="container">
        <Content>
          <div>
            <div>
              <label className="label__top">ดาวน์โหลด!</label>
              <label className="label__buttom">
                พร้อมแล้วทั้ง iOS และ Android
              </label>
            </div>

            <div className="img__download">
              <a href="https://play.google.com/store/apps/details?id=com.klassbits.klassbits" target="_blank">
                <img
                  src={process.env.PUBLIC_URL + "/imgs/android__download.png"}
                />
              </a>
              <a href="https://apps.apple.com/sa/app/klassbits/id1630706840?uo=2" target="_blank">
                <img src={process.env.PUBLIC_URL + "/imgs/download__ios.png"} />
              </a>
            </div>
          </div>

          <div>
            <img src={process.env.PUBLIC_URL + "/imgs/img_dowload.png"} />
          </div>
        </Content>
      </div>
    </Background>
  );
};

export default DownloadPage;
