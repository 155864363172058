import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { CardBody, Content, Background  ,SwiperPC ,SwiperMo} from "../style/TestAppStyle";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay } from "swiper";

const TestApp = () => {
  return (
    <Background>
      <div className="container">
        <Content>
          <div className="label__top">
            <div>Klass Bits Personalized Learning</div>
            <div>ระบบประเมินและเสริมสร้างศักยภาพเรียนรู้รายบุคคลสำหรับนักเรียน</div>
          </div>

          <SwiperPC>
            <Swiper
              slidesPerView={5}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              centeredSlides={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <CardBody>
                  <img src={process.env.PUBLIC_URL + "/imgs/test__App1.png"} />
                </CardBody>
              </SwiperSlide>
              <SwiperSlide>
                <CardBody>
                  <img src={process.env.PUBLIC_URL + "/imgs/test__App2.png"} />
                </CardBody>
              </SwiperSlide>
              <SwiperSlide>
                <CardBody>
                  <img src={process.env.PUBLIC_URL + "/imgs/test__App3.png"} />
                </CardBody>
              </SwiperSlide>
              <SwiperSlide>
                <CardBody>
                  <img src={process.env.PUBLIC_URL + "/imgs/test__App4.png"} />
                </CardBody>
              </SwiperSlide>
              <SwiperSlide>
                <CardBody>
                  <img src={process.env.PUBLIC_URL + "/imgs/test__App5.png"} />
                </CardBody>
              </SwiperSlide>
            </Swiper>
          </SwiperPC>

          
          <SwiperMo>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              centeredSlides={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <CardBody>
                  <img src={process.env.PUBLIC_URL + "/imgs/test__App1.png"} />
                </CardBody>
              </SwiperSlide>
              <SwiperSlide>
                <CardBody>
                  <img src={process.env.PUBLIC_URL + "/imgs/test__App2.png"} />
                </CardBody>
              </SwiperSlide>
              <SwiperSlide>
                <CardBody>
                  <img src={process.env.PUBLIC_URL + "/imgs/test__App3.png"} />
                </CardBody>
              </SwiperSlide>
              <SwiperSlide>
                <CardBody>
                  <img src={process.env.PUBLIC_URL + "/imgs/test__App4.png"} />
                </CardBody>
              </SwiperSlide>
              <SwiperSlide>
                <CardBody>
                  <img src={process.env.PUBLIC_URL + "/imgs/test__App5.png"} />
                </CardBody>
              </SwiperSlide>
            </Swiper>
          </SwiperMo>

        </Content>
      </div>
    </Background>
  );
};

export default TestApp;
