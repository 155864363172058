import styled from "styled-components";

export const ButtonGet = styled.button`
  border: 4px solid #ffffff;
  border-radius: 40px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  background: #ffb146;
  padding: 3px 10px 3px 20px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
  position: relative;

  @media only screen and (max-width: 991px) {
    padding: 3px 10px 3px 20px;
    font-size: 16px;
  }
  
`;
