import Banner from "../componente/Banner";
import Contact from "../componente/Contact";
import Courses from "../componente/Courses";
import TestApp from "../componente/TestApp";
import DownloadPage from "../componente/DownloadPage";
import ForSchools from "../componente/ForSchools";
import ForParents from "../componente/ForParents";
import Footer from "../componente/Footer";
import Navbar from "../componente/Navbar";
import { useRef } from "react";

import { Background } from "../style/Background";

const KlassBitsPage = () => {
  const refBanner = useRef();
  const refForSchools = useRef();
  const refForParents = useRef();
  const refAbuotUs = useRef();
  const refCourses = useRef();
  const refContact = useRef();

  const onChangeHandle = (myRef) => {
    switch (myRef) {
      case "Schools":
        return refForSchools.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      case "Courses":
        return refCourses.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      case "Contact":
        return refContact.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });

      case "Parents":
        return refForParents.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });

      default:
        return refBanner.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
    }
  };

  return (
    <>
      <Navbar onChangeHandle={onChangeHandle} />
      <Background ref={refBanner}>
        <Banner />
      </Background>

      <div ref={refForSchools}>
        <ForSchools  />
      </div>

      <div ref={refForParents} >
        <ForParents />
      </div>

      <div ref={refCourses} >
        <Courses />
      </div>

      <div>
        <TestApp />
      </div>

      <div>
        <DownloadPage />
      </div>

      <div  ref={refContact} >
        <Contact />
      </div>

      <Footer onChangeHandle={onChangeHandle} />
    </>
  );
};

export default KlassBitsPage;
