import styled from "styled-components";

export const Content = styled.div`
  position: fixed;
  top: 0;
  background-color: #f5674c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  z-index: 10;
  height: 110px;
  display: flex;
  align-items: center;

  .nav__boby {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav__logo {
    width: 200px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));
      cursor: pointer;
    }
  }

  .nav__menu {
    background: #f4674c;
    border: 3px solid #f47a69;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    height: 60px;
    align-items: center;
    padding-left: 30px;

    > * {
      padding-right: 30px;
      cursor: pointer;
      ::before {
        content: " ";
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 5px;
        background: #ffdc31;
        border-radius: 7.5px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .nav__icon {
    display: flex;
    > * {
      border-radius: 50%;
      height: 40px;
      width: 40px;
      background-color: #ffffff;
      margin-left: 10px;
    }
  }

  .nav__phone {
    background: #c2351a;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;
    margin-left: 10px;
    > * {
      color: #ffffff;
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1199px) {
    .nav__logo {
      width: 150px;
    }
    .nav__menu {
      > * {
        padding-right: 20px;
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .nav__logo {
      width: 120px;
      height: 50px;
    }

    .nav__menu {
      padding-left: 10px;
      height: 50px;
      > * {
        padding-right: 12px;
        font-size: 12px;
      }
    }

    .nav__phone {
      padding: 8px;
      margin-left: 8px;
      > * {
        font-size: 10px;
      }
    }

    .nav__icon {
      > * {
        height: 30px;
        width: 30px;
        margin-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const ContentMobile = styled.div`
  @media only screen and (min-width: 768px) {
    display: none;
  }
  position: fixed;
  top: 0;
  background: linear-gradient(180deg, #f5674c 0%, #d74a2f 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: height 0.2s;
  z-index: 10;

  .img_logo {
    width: 100%;
    display: flex;
    justify-content: center;
    osition: relative;

    img {
      width: 35%;
      height: 100%;
    }

    > *:last-child {
      position: absolute;
      right: 20px;
      top: 22px;
      color: white;
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 420px) {
    .img_logo {
      img {
        width: 25%;
      }
    }
  }

`;

export const MenuMobile = styled.div`
  @media only screen and (min-width: 768px) {
    display: none;
  }
  position: fixed;
  top: 0;
  background: linear-gradient(180deg, #f5674c 0%, #d74a2f 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100vh;
  width: 0%;
  transition: width 0.2s;
  overflow: hidden;
  z-index: 10;

  .logo_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;

    img {
      width: 45%;
      height: 100%;
    }

    > *:last-child {
      color: white;
      cursor: pointer;
    }
  }

  .text_menu {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;

    > * {
      padding-top: 25px;
      padding-bottom: 25px;
      cursor: pointer;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
      width: 100%;
      text-align: end;

      ::before {
        content: " ";
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 5px;
        background: #ffdc31;
        border-radius: 7.5px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .bt-login {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 35px;
  }
`;
