import styled from "styled-components";

export const Content = styled.div`
  .label__top {
    margin-top: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 350px;
    }

    .lable__heard {
      margin-top: 25px;
      font-size: 45px;
      font-weight: 600;
      margin-bottom: 40px;
    }

    > *:last-child {
      margin-bottom: 20px;
    }
  }

  .label__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 250px;
  }

  .label__heard {
    font-size: 18px;
    font-weight: 600;
  }
`;
